import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "utils/array-util"

const LinkMap = {
  "Simple Calculator": "60047eeeca24",
  "Layout Design": "60047eeeca24",
  "Flex Layout": "60047eeeca24",
  "Table Layout": "60047eeeca24",
  "Sizing & Coloring": "60047eeeca24",

  CSS: "7537e3a309e5",
  "How Render CSS": "7537e3a309e5",
  "CSS Rule Anatomy": "7537e3a309e5",
  "CSS Selectors": "7537e3a309e5",
  "CSS Texts": "7537e3a309e5",
  "CSS Box Model": "7537e3a309e5",
  "CSS Layouts": "7537e3a309e5",
  "CSS Media Query": "7537e3a309e5",
  "CSS Pseudo Code": "7537e3a309e5",
  "CSS Overflow": "7537e3a309e5",
  "CSS Animation": "7537e3a309e5",

  Theme: "5a9bb4065847",
  "Light Theme": "5a9bb4065847",
  "Dark Theme": "5a9bb4065847",
  "CSS Variable": "5a9bb4065847",
  SaSS: "5a9bb4065847",

  "React-Font": "1a02425df0c1",
  Typography: "1a02425df0c1",
  Typeface: "1a02425df0c1",
  Font: "1a02425df0c1",
  "Google Fonts": "1a02425df0c1",

  "React Icons": "583e45cffdab",
  FontAwesome: "583e45cffdab",
  ReactIcon: "583e45cffdab",
  SVG: "583e45cffdab",
  Icon: "583e45cffdab",

  Responsive: "e929a082a0ed",
  "Media Query": "e929a082a0ed",
  "Viewport Unit": "e929a082a0ed",

  "pixel,em,rem": "6515563c69b4",
  "CSS Units": "6515563c69b4",

  "Post Processors": "5de937f8ba2f",
  "Pre Processors": "5de937f8ba2f",
  Sass: "5de937f8ba2f",
  "Style Components": "5de937f8ba2f",
  "Tailwind CSS": "5de937f8ba2f",
  ChakraUI: "5de937f8ba2f",

  "Atomic CSS": "601ec7b5f587",
  "Functional CSS": "601ec7b5f587",
  ooCSS: "601ec7b5f587",

  "Color Model": "c97c9c27798b",
  "RGB Color Model": "c97c9c27798b",
  "CMY Color Model": "c97c9c27798b",
  "HSL Color Model": "c97c9c27798b",
  "HSV Color Model": "c97c9c27798b",
  "Hex Code": "c97c9c27798b",

  "Design System": "7e62e1da3438",
  "Figma Structure": "8b2a2fd87045",
  "Icon Management": "e7154deb9323",

  "Write Own Style": "1dc282e27002",
}

const storiesBasic = [
  {
    title: "Kuş bakışı CSS",
    postId: "7537e3a309e5",
  },
  {
    title: "CSS Units (pixel,em,rem)",
    postId: "6515563c69b4",
  },
  {
    title: "Renk Modelleri (RGB, CMY, HSV, HSL)",
    postId: "c97c9c27798b",
  },
  {
    title: "Dark & Light Theme (Variables)",
    postId: "5a9bb4065847",
  },
  {
    title: "Fontları React Projesine Nasıl Dahil Ederiz",
    postId: "1a02425df0c1",
  },
  {
    title: "Iconları React Projesine Nasıl Dahil Ederiz",
    postId: "583e45cffdab",
  },
  {
    title: "Büyük Ölçekli Projelerde Styling",
    postId: "5de937f8ba2f",
  },
  {
    title: "Atomic CSS Nedir?",
    postId: "601ec7b5f587",
  },
]

const storiesCalc = [
  {
    title: "Basit Hesap Makinesi Stilllendirme",
    postId: "60047eeeca24",
  },
  {
    title: "Basit Hesap Makinesi Responsive Tasarım",
    postId: "e929a082a0ed",
  },
]

const storiesArchitecture = [
  {
    title: "React Proje Mimarisi-4 (Icon Management)",
    postId: "e7154deb9323",
  },
  {
    title: "React Proje Mimarisi-5 (Figma-Arch Sync)",
    postId: "8b2a2fd87045",
  },
  {
    title: "React Proje Mimarisi-7 (Design System)",
    postId: "7e62e1da3438",
  },
  {
    title: "React Proje Mimarisi-8 (Own Style & Naming)",
    postId: "1dc282e27002",
  },
  {
    title: "React Proje Mimarisi-9 (CSS Scope)",
    postId: "e07a2189aafc",
  },
  {
    title: "React Proje Mimarisi-10 (Color Palette)",
    postId: "5b9137c4b5a3",
  },
  {
    title: "React Proje Mimarisi -11 (Text)",
    postId: "91dc2de6fb24",
  },
  {
    title: "React Proje Mimarisi -23 (Layout Styling)",
    postId: "c4faa40275c3",
  },
]

const storiesGroup = [
  {
    title: "Temel CSS Mekanikleri",
    storyPart: storiesBasic,
  },
  {
    title: "Styling Mimarisi",
    storyPart: storiesArchitecture,
  },
  {
    title: "Hesap Makinesi Stillendirme",
    storyPart: storiesCalc,
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "React Styling",
  path: "react-ui-styling",
}

const ReactUIStylingPage = () => {
  return (
    <StoryGroup
      title={context.title}
      complex={true}
      stories={storiesGroup}
      linkMap={context.linkMap}
    />
  )
}

export default ReactUIStylingPage
